import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-need-service',
  templateUrl: './need-service.component.html',
  styleUrls: ['./need-service.component.scss']
})
export class NeedServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
