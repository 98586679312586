import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OwlOptions } from "ngx-owl-carousel-o";
import { DataService } from "../data.service";

@Component({
  selector: "tsu-product-detail",
  templateUrl: "./product-detail.component.html",
  styleUrls: ["./product-detail.component.scss"]
})
export class ProductDetailComponent implements OnInit {
  @Input() dtitle = "";
  @Input() dsubTitle = "";
  @Input() dsDetail = "";
  @Input() dRecommendedUse = "";
  @Input() dbgImage = "";
  @Input() dproductImages = "";
  @Input() dbackPageName = "";
  @Input() downloadFileName = "";
  @Input() downloadFolderName = "";
  @Input() showDownload = true;

  @Input() dleft1 = "";
  @Input() dright1 = "";
  @Input() dleft2 = "";
  @Input() dright2 = "";
  @Input() dleft3 = "";
  @Input() dright3 = "";
  constructor(private sharedData: DataService, private router: Router) {}
  customOptions: OwlOptions = {
    loop: true,
    margin: 20,
    nav: true,
    smartSpeed: 500,
    autoplay: true,
    navText: [
      '<span class="fa fa-angle-left"></span>',
      '<span class="fa fa-angle-right"></span>'
    ],
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };
  ngOnInit() {}

  back() {
    // this.sharedData.data.next('');
    if (this.dbackPageName === "Special"){
      this.router.navigate(["/products/Special"]);
    }
    if (this.dbackPageName === "Exterior"){
      this.router.navigate(["/products/Exterior"]);
    }
    if (this.dbackPageName === "Interior"){
      this.router.navigate(["/products/Interior"]);
    }
    if (this.dbackPageName === "Primers"){
      this.router.navigate(["/products/Primers"]);
    }
  }

}
