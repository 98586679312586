import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-technical-data',
  templateUrl: './technical-data.component.html',
  styleUrls: ['./technical-data.component.scss']
})
export class TechnicalDataComponent implements OnInit {
  @Input() left1 = "";
  @Input() right1 = "";
  @Input() left2 = "";
  @Input() right2 = "";
  @Input() left3 = "";
  @Input() right3 = "";
  constructor() { }

  ngOnInit() {
  }

}
