import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {
  @Input() title = "";
  @Input() subTitle = "";
  @Input() bgImage = "";
  constructor() { }

  ngOnInit() {
  }

}
