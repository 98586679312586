import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation
} from "@angular/core";

@Component({
  selector: "tsu-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit {
  imageUrls: (string | any)[] = [
    {
      url: "assets/images/background/Slider1.jpg",
      caption: `<h1>Welcome to<br>Prime Colour Products</h1>
      <p> Providing Paint Products Solutions Since 1967</p>`
    },
    {
      url: "assets/images/background/Slider5.jpg",
      caption: `<h1>Redefining the Concept of Paints</h1>
      <p>We deals in the manufacturing and selling of all kinds of paints, such as decorative paints, coatings, ancillaries, and products related to home decor and related services. </p>`
    },
    {
      url: "assets/images/background/Slider3.png",
      caption: `<h1>Transform your living room & bedroom with an excellent finish</h1>
      <p>Find the best interior paints and distempers at Prime Colour Products, and dress up your walls and ceilings in soft and
       gentle hues. Interior emulsions are water-based and easy to apply on the walls and ceilings.</p>`
    },
    {
      url: "assets/images/background/slider2.jpg",
      caption: `<h1>Transform your living room & bedroom with an excellent finish</h1>
      <p>Find the best interior paints and distempers at Prime Colour Products, and dress up your walls and ceilings in soft and
       gentle hues. Interior emulsions are water-based and easy to apply on the walls and ceilings.</p>`
    },
    {
      url: "assets/images/background/Slider4.jpg",
      caption: `<h1>Bulk Manufacturer of Primer and Putties</h1>
      <p>Our products are made in-house using advanced processing techniques. Also, the whole array is packed in quality packaging material after passing the inspection tests that are conducted by dexterous experts.</p>`
    },
    {
      url: "assets/images/background/Slider6.jpg",
      caption: `<h1>Wide range of Exterior Paints</h1>
      <p>The products supplied by our company are best with it comes to quality, consistency and affordable rates. </p>`
    }
    ,
    {
      url: "assets/images/background/Slider7.png",
      caption: `<h1>Wide range of Exterior Paints</h1>
      <p>The products supplied by our company are best with it comes to quality, consistency and affordable rates. </p>`
    }
    ,
    {
      url: "assets/images/background/Slider8.png",
      caption: `<h1>Wide range of Exterior Paints</h1>
      <p>The products supplied by our company are best with it comes to quality, consistency and affordable rates. </p>`
    }
    // ,
    // {
    //   url: "assets/images/backgrounds/13.jpg",
    //   caption: `<h1 style="border-bottom:red"> AKSHAY RESIDENCY</h1>
    //   <p>Own this expressway, Presenting 1 & 2 BHK Luxurious Homes that offer you indoors full of warmth, happiness</p>`
    // },
    // {
    //   url: "assets/images/banners/14.jpg",
    //   caption: `<h1> Riddhi Siddhi Avenue, Wakad</h1>
    //   <p>Own this expressway, Presenting 2 BHK Luxurious Homes that offer you indoors full of warmth, happiness</p>`
    // }
  ];
  height = "500px";
  minHeight: "500px";
  arrowSize = "20px";
  showArrows = true;
  disableSwiping = false;
  autoPlay = true;
  autoPlayInterval = 5555;
  stopAutoPlayOnSlide = true;
  debug = false;
  backgroundSize = "cover";
  backgroundPosition = "center center";
  backgroundRepeat = "no-repeat";
  showDots = true;
  dotColor = "#FFF";
  showCaptions = true;
  captionColor = "#FFF";
  captionBackground = "rgba(0, 0, 0, .35)";
  lazyLoad = false;
  hideOnNoSlides = false;
  width = "100%";
  fullscreen = false;
  enableZoom = false;
  enablePan = false;
  noLoop = false;

  screenHeight: number;
  screenWidth: number;
  constructor() {}

  ngOnInit() {
    this.getScreenSize();
  }
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.height =
      (window.innerHeight - window.innerHeight / 4.7).toString() + "px";
  }
}
