import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-clients-item',
  templateUrl: './clients-item.component.html',
  styleUrls: ['./clients-item.component.scss']
})
export class ClientsItemComponent implements OnInit {
  @Input() title = "";
  @Input() bgImage = "";
  constructor() { }

  ngOnInit() {
  }

}
