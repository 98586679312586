export var single = [
  {
    "name": "Quality",
    "value": 90
  },
  {
    "name": "Perfection",
    "value": 80
  },
  {
    "name": "Finishing",
    "value": 90
  }
];
