import { Component, OnInit, enableProdMode, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'tsu-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientLogoComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: -180,
    nav: true,
    smartSpeed: 500,
    autoplay: true,
    navText: [
      '<span class="fa fa-angle-left"></span>',
      '<span class="fa fa-angle-right"></span>'
    ],
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    }
  };
  constructor() { }

  ngOnInit() {
  }

}
