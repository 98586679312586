import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ClientsDetailComponent } from './clients-detail/clients-detail.component';

const routes: Routes = [{ path: "", component: HomeComponent },
{ path: "home", component: HomeComponent },
{
  path: "contact",
  loadChildren: () => import("./contact/contact.module").then(m => m.ContactModule)
},
{
  path: "about",
  loadChildren: () => import("./aboutus/aboutus.module").then(m => m.AboutusModule)
},
{
  path: "products",
  loadChildren: () => import("./products/products.module").then(m => m.ProductsModule)
},
{
  path: "color-pallate",
  loadChildren: () => import("./color-pallate/color-pallate.module").then(m => m.ColorPallateModule)
},
{
  path: "privacy",
  loadChildren: () => import("./privacy-module/privacy-module.module").then(m => m.PrivacyModuleModule)
},
{ path: "clients", component: ClientsDetailComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
