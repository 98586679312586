import { Component, OnInit } from '@angular/core';
import { single } from './data';

@Component({
  selector: 'tsu-any-solution',
  templateUrl: './any-solution.component.html',
  styleUrls: ['./any-solution.component.scss']
})
export class AnySolutionComponent implements OnInit {
  single: any[];
  view: any[] = [700, 200];



  // options
  showXAxis: boolean = false;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  yAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  xAxisLabel: string = 'Population';

  colorScheme = {
    domain: ['#f50c1a', '#5AA454', '#004aad', '#C7B42C', '#AAAAAA'],
  };
  constructor() {
    Object.assign(this, { single });
  }

  ngOnInit() {
  }

}
