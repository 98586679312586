import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-featured-home-products',
  templateUrl: './featured-home-products.component.html',
  styleUrls: ['./featured-home-products.component.scss']
})
export class FeaturedHomeProductsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
