import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { SlideshowModule } from "ng-simple-slideshow";
import { PageBannerComponent } from "../page-banner/page-banner.component";
import { AboutHomeComponent } from "../about-home/about-home.component";
import { ClientLogoComponent } from "../client-logo/client-logo.component";
import { NeedServiceComponent } from '../need-service/need-service.component';
import { FeaturedHomeProductsComponent } from "../featured-home-products/featured-home-products.component";
import { ProductLogoComponent } from "../product-logo/product-logo.component";
import { InnerBoxComponent } from '../inner-box/inner-box.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { TechnicalDataComponent } from './technical-data/technical-data.component';
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatChipsModule,
  MatListModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatSliderModule,
  MatRadioModule,
  MatDialogModule,
  MatGridListModule,
  MatExpansionModule,
  MatTabsModule
} from "@angular/material";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    PageBannerComponent,
    AboutHomeComponent,
    ClientLogoComponent,
    NeedServiceComponent,
    FeaturedHomeProductsComponent,
    ProductLogoComponent,
    InnerBoxComponent,
    ProductDetailComponent,
    TechnicalDataComponent
  ],
  imports: [
    CommonModule,
    NgbCarouselModule,
    CarouselModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    SlideshowModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    MatExpansionModule,
    MatTabsModule,
    ToastrModule.forRoot()
  ],
  exports: [
    CommonModule,
    NgbCarouselModule,
    CarouselModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    SlideshowModule,
    PageBannerComponent,
    AboutHomeComponent,
    ClientLogoComponent,
    NeedServiceComponent,
    FeaturedHomeProductsComponent,
    ProductLogoComponent,
    InnerBoxComponent,
    ProductDetailComponent,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatGridListModule,
    MatExpansionModule,
    MatTabsModule
  ]
})
export class SharedModuleModule {}
