import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tsu-inner-box',
  templateUrl: './inner-box.component.html',
  styleUrls: ['./inner-box.component.scss']
})
export class InnerBoxComponent implements OnInit {
  @Input() title = "";
  @Input() subTitle = "";
  @Input() bgImage = "";
  @Input() inpRouterLink = "";
  @Output() callEmit = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }
}
