import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { SliderComponent } from "./slider/slider.component";
import { ClientLogoComponent } from "./client-logo/client-logo.component";
import { FeatureComponent } from "./feature/feature.component";
import { HomeComponent } from "./home/home.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { TermsandconditionComponent } from "./privacy/termsandcondition/termsandcondition.component";
import { SharedModuleModule } from "./shared-module/shared-module.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BestChoiceComponent } from './best-choice/best-choice.component';
import { AnySolutionComponent } from './any-solution/any-solution.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ToastrModule } from "ngx-toastr";
import { ClientsDetailComponent } from './clients-detail/clients-detail.component';
import { ClientsItemComponent } from './clients-item/clients-item.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    FeatureComponent,
    HomeComponent,
    PrivacyComponent,
    TermsandconditionComponent,
    BestChoiceComponent,
    AnySolutionComponent,
    ClientsDetailComponent,
    ClientsItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModuleModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
